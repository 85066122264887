var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "container_box" },
        [
          _c("el-card", { staticClass: "box-card mb20" }, [
            _c("div", { staticClass: "acea-row row-center row-middle" }, [
              _c(
                "div",
                {
                  staticClass: "pointer",
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-arrow-left" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "pl10" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "marketing.group.groupActivity.creatGroup.5u4e1nlsjk00"
                        )
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "f-s-18 f-w-500 ml32 add_title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "marketing.group.groupActivity.creatGroup.5u4e1nlsk7w0"
                      )
                    ) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-card",
            { staticClass: "box-card box-body" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  !_vm.$route.params.activityId
                    ? _c("el-tab-pane", {
                        attrs: {
                          label: _vm.$t(
                            "marketing.group.groupActivity.creatGroup.5u4e1nlskag0"
                          ),
                          name: "first",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      label: _vm.$t(
                        "marketing.group.groupActivity.creatGroup.5u4e1nlskd80"
                      ),
                      name: "second",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "small",
                  },
                },
                [
                  _vm.activeName == "first" &&
                  !_vm.$route.params.isActivity !== "1"
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "选择活动:", prop: "id" } },
                          [
                            _vm.isCopy
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.form.productList.activityName)
                                  ),
                                ])
                              : _c(
                                  "el-select",
                                  {
                                    directives: [
                                      {
                                        name: "selectLoadMore",
                                        rawName: "v-selectLoadMore",
                                        value: _vm.selectLoadMore,
                                        expression: "selectLoadMore",
                                      },
                                    ],
                                    staticClass: "selWidth",
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      loading: _vm.loading,
                                      remote: "",
                                      disabled: _vm.isCopy,
                                      "remote-method": _vm.remoteMethod,
                                      placeholder: _vm.$t(
                                        "marketing.group.groupActivity.creatGroup.5u4e1nlskfg0"
                                      ),
                                    },
                                    model: {
                                      value: _vm.form.id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "id", $$v)
                                      },
                                      expression: "form.id",
                                    },
                                  },
                                  _vm._l(_vm.activityList, function (user) {
                                    return _c("el-option", {
                                      key: user.id,
                                      attrs: {
                                        label: user.name,
                                        value: user.id,
                                        disabled: Number(user.status) > 1,
                                      },
                                    })
                                  }),
                                  1
                                ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName == "second"
                    ? [
                        _c(
                          "div",
                          { staticClass: "acea-row row-between-wrapper" },
                          [
                            _c(
                              "div",
                              { staticClass: "acea-row mb20" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      disabled: !_vm.isEdit && _vm.isCopy,
                                      size: "small",
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addGoods()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "marketing.group.groupActivity.creatGroup.5u4e1nlskd80"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown",
                                  {
                                    staticClass: "ml10 mr10",
                                    attrs: {
                                      size: "small",
                                      "split-button": "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "marketing.group.groupActivity.creatGroup.5u4e1nlskhw0"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              disabled: _vm.isShowCheck,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.setPrice(2)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "marketing.group.groupActivity.creatGroup.5u4e1nlskjo0"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              disabled: _vm.isShowCheck,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.setPrice(1)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "marketing.group.groupActivity.creatGroup.5u4e1nlskl80"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      disabled: _vm.isShowCheck,
                                    },
                                    on: { click: _vm.batchDel },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "marketing.group.groupActivity.creatGroup.5u4e1nlskms0"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            ref: "tableList",
                            staticStyle: { width: "100%" },
                            attrs: {
                              "row-key": "id",
                              data: _vm.proData,
                              size: "small",
                              "default-expand-all": "",
                              "tree-props": { children: "children" },
                            },
                          },
                          [
                            _c("el-table-column", { attrs: { width: "30" } }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { width: "50" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _c("el-checkbox", {
                                          attrs: {
                                            indeterminate: _vm.isIndeterminate,
                                          },
                                          on: {
                                            change: _vm.handleCheckAllChange,
                                          },
                                          model: {
                                            value: _vm.checkAll,
                                            callback: function ($$v) {
                                              _vm.checkAll = $$v
                                            },
                                            expression: "checkAll",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return !scope.row.sku
                                        ? [
                                            _c("el-checkbox", {
                                              attrs: {
                                                value: scope.row.checked,
                                              },
                                              on: {
                                                change: function (v) {
                                                  return _vm.handleCheckOneChange(
                                                    v,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "min-width": "300",
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u4e1nlskpc0"
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", { staticClass: "acea-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview mr10",
                                            },
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "36px",
                                                  height: "36px",
                                                },
                                                attrs: {
                                                  src: scope.row.image,
                                                  "preview-src-list": [
                                                    scope.row.image,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "row_title line2" },
                                            [_vm._v(_vm._s(scope.row.name))]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1876274800
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "categoryName",
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u4e1nlskrg0"
                                ),
                                "min-width": "80",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "price",
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u4e1nlsktg0"
                                ),
                                width: "120",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "stock",
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u4e1nlskv00"
                                ),
                                "min-width": "80",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "quota",
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u4e1nlskjo0"
                                ),
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return scope.row.sku
                                        ? [
                                            _c("el-input-number", {
                                              staticClass: "input_width",
                                              attrs: {
                                                type: "number",
                                                precision: 0,
                                                min: 0,
                                                max: scope.row.stock,
                                                controls: false,
                                              },
                                              model: {
                                                value: scope.row.quota,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "quota",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.quota",
                                              },
                                            }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "activityPrice",
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u4e1nlskww0"
                                ),
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return scope.row.sku
                                        ? [
                                            _c("el-input-number", {
                                              staticClass: "input_width",
                                              attrs: {
                                                type: "number",
                                                precision: 2,
                                                min: 0.01,
                                                max: 99999,
                                                controls: false,
                                              },
                                              model: {
                                                value: scope.row.activityPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "activityPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.activityPrice",
                                              },
                                            }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u4e1nlskyk0"
                                ),
                                width: "60",
                                fixed: "right",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        !scope.row.sku
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete(
                                                      scope.$index,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "marketing.group.groupActivity.creatGroup.5u4efahd9800"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1329947204
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            { staticClass: "fixed-card", attrs: { "dis-hover": "" } },
            [
              _c(
                "div",
                { staticClass: "acea-row row-center-wrapper" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName === "first",
                          expression: "activeName === 'first'",
                        },
                      ],
                      attrs: {
                        disabled: !_vm.form.id && _vm.activeName === "first",
                        size: "small",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.activeName = "second"
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "marketing.group.groupActivity.creatGroup.5u4efahda440"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:group:product:add"],
                          expression: "['merchant:group:product:add']",
                        },
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName == "second",
                          expression: "activeName == 'second'",
                        },
                        {
                          name: "debounceClick",
                          rawName: "v-debounceClick",
                          value: function () {
                            _vm.submitForm("form")
                          },
                          expression:
                            "\n            () => {\n              submitForm('form');\n            }\n          ",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "marketing.group.groupActivity.creatGroup.5u4efahda700"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("activity", {
        key: "keyNum",
        ref: "activityModal",
        on: { onChange: _vm.setActivity },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }