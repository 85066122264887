import { render, staticRenderFns } from "./404.vue?vue&type=template&id=cb6dfc12&scoped=true"
import script from "./404.vue?vue&type=script&lang=js"
export * from "./404.vue?vue&type=script&lang=js"
import style0 from "./404.vue?vue&type=style&index=0&id=cb6dfc12&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb6dfc12",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\beigan-merchant-backstage\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cb6dfc12')) {
      api.createRecord('cb6dfc12', component.options)
    } else {
      api.reload('cb6dfc12', component.options)
    }
    module.hot.accept("./404.vue?vue&type=template&id=cb6dfc12&scoped=true", function () {
      api.rerender('cb6dfc12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/error-page/404.vue"
export default component.exports