"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRole = addRole;
exports.delRole = delRole;
exports.getInfo = getInfo;
exports.getRoleList = getRoleList;
exports.menuCacheList = menuCacheList;
exports.updateRole = updateRole;
exports.updateRoleStatus = updateRoleStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 新增
 * @param
 */
function addRole(pram) {
  var data = {
    roleName: pram.roleName,
    status: pram.status,
    rules: pram.rules,
    merId: pram.merId
  };
  // data.rules = pram.rules.join(',')
  return (0, _request.default)({
    url: '/admin/merchant/role/save',
    method: 'POST',
    data: data
  });
}

/**
 * 删除
 * @param
 */
function delRole(id) {
  return (0, _request.default)({
    url: "admin/merchant/role/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 详情
 * @param
 */
function getInfo(pram) {
  return (0, _request.default)({
    url: "/admin/merchant/role/info/".concat(pram),
    method: 'GET'
  });
}

/**
 * 分页列表
 * @param
 */
function getRoleList(pram) {
  var data = {
    page: pram.page,
    limit: pram.limit,
    roleName: pram.roleName,
    status: pram.status
  };
  return (0, _request.default)({
    url: '/admin/merchant/role/list',
    method: 'get',
    params: data
  });
}

/**
 * 修改
 * @param
 */
function updateRole(pram) {
  var data = {
    id: pram.id,
    roleName: pram.roleName,
    rules: pram.rules,
    status: pram.status,
    merId: pram.merId
  };
  return (0, _request.default)({
    url: '/admin/merchant/role/update',
    method: 'post',
    data: data
  });
}

/**
 * 修改身份状态
 * @param
 */
function updateRoleStatus(pram) {
  return (0, _request.default)({
    url: '/admin/merchant/role/updateStatus',
    method: 'get',
    params: {
      id: pram.id,
      status: pram.status,
      merid: pram.merid
    }
  });
}

/**
 * 缓存菜单
 * @param
 */
function menuCacheList(pram) {
  return (0, _request.default)({
    url: '/admin/merchant/menu/cache/tree',
    method: 'get'
  });
}