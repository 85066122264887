var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      {
        staticClass: "backgroundColor",
        staticStyle: { "padding-top": "20px", "padding-bottom": "0" },
      },
      [
        _c(
          "el-form",
          { ref: "queryForm", attrs: { model: _vm.queryParams, inline: true } },
          [
            _c(
              "el-row",
              { staticClass: "mb8", attrs: { gutter: 10 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 14 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "交易时间" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            clearable: "",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": _vm.queryParams.beginTime,
                            "end-placeholder": _vm.queryParams.endTime,
                          },
                          on: { change: _vm.timeChange },
                          model: {
                            value: _vm.timeList,
                            callback: function ($$v) {
                              _vm.timeList = $$v
                            },
                            expression: "timeList",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-row",
              {
                staticClass: "mb8",
                attrs: { gutter: 10, type: "flex", justify: "space-between" },
              },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          size: "mini",
                        },
                        on: { click: _vm.handleQuery },
                      },
                      [_vm._v("搜索")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: { click: _vm.resetQuery },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 1.5 } }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content backgroundColor" },
      [
        [
          _c(
            "el-table",
            {
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "highlight-current-row": true,
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "serialNo", label: "序号", "min-width": "110" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "orderFlowNo", label: "交易流水号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "debitAmount", label: "借方金额" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "loanAmount", label: "贷方金额" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "balance", label: "余额" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "开账币种" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              scope.row.currencyType == 156 ? "人民币" : "其他"
                            ) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "记账币种" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              scope.row.currencyType1 == 156 ? "人民币" : "其他"
                            ) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n              " +
                            _vm._s(scope.row.transferDate) +
                            " " +
                            _vm._s(scope.row.transferTime) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }