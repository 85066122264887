"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.menuListApi = menuListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 菜单
 * @param pram
 */
function menuListApi() {
  return (0, _request.default)({
    url: '/admin/merchant/getMenus',
    method: 'GET'
  });
}