"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategroy = addCategroy;
exports.articleInfoApi = articleInfoApi;
exports.categroyByIds = categroyByIds;
exports.categroyUpdateStatus = categroyUpdateStatus;
exports.deleteCategroy = deleteCategroy;
exports.infoCategroy = infoCategroy;
exports.listCategroy = listCategroy;
exports.treeCategroy = treeCategroy;
exports.updateCategroy = updateCategroy;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 新增分类
 * @param pram
 */
function addCategroy(pram) {
  var data = {
    extra: pram.extra,
    name: pram.name,
    pid: pram.pid,
    sort: pram.sort,
    status: pram.status,
    type: pram.type,
    url: pram.url,
    owner: pram.owner
  };
  return (0, _request.default)({
    url: '/admin/merchant/category/save',
    method: 'POST',
    data: data
  });
}

/**
 * 分类详情
 * @param pram
 */
function infoCategroy(pram) {
  return (0, _request.default)({
    url: "/admin/merchant/category/info/".concat(pram.id),
    method: 'GET'
  });
}

/**
 * 删除分类
 * @param pram
 */
function deleteCategroy(pram) {
  return (0, _request.default)({
    url: "/admin/merchant/category/delete/".concat(pram.id),
    method: 'post'
  });
}

/**
 * 分类列表
 * @param pram
 */
function listCategroy(pram) {
  var data = {
    limit: pram.limit,
    name: pram.name,
    page: pram.page,
    pid: pram.pid,
    status: pram.status,
    type: pram.type
  };
  return (0, _request.default)({
    url: '/admin/category/list',
    method: 'GET',
    params: data
  });
}

/**
 * 分类数据tree数据
 * @param pram
 */
function treeCategroy(pram) {
  var data = {
    type: pram.type,
    status: pram.status,
    name: pram.name,
    owner: pram.owner
  };
  return (0, _request.default)({
    url: '/admin/merchant/category/list/tree',
    method: 'GET',
    params: data
  });
}

/**
 * 更新分类
 * @param pram
 */
function updateCategroy(pram) {
  var data = {
    extra: pram.extra,
    name: pram.name,
    pid: pram.pid,
    sort: pram.sort,
    status: pram.status,
    type: pram.type,
    url: pram.url,
    id: pram.id,
    owner: pram.owner
  };
  return (0, _request.default)({
    url: "/admin/merchant/category/update/".concat(pram.id),
    method: 'POST',
    params: data
  });
}

/**
 * 根据id集合查询对应分类列表
 * @param pram
 */
function categroyByIds(pram) {
  var data = {
    ids: pram.ids
  };
  return (0, _request.default)({
    url: '/admin/merchant/category/list/ids',
    method: 'GET',
    params: data
  });
}

/**
 * 修改 显示关闭状态
 * @param pram
 */
function categroyUpdateStatus(id) {
  return (0, _request.default)({
    url: "/admin/merchant/category/updateStatus/".concat(id),
    method: 'GET'
  });
}

/**
 * 文章详情
 * @param pram
 */
function articleInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/article/info",
    method: 'GET',
    params: params
  });
}