"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merchant = require("@/api/merchant");
var _permission = require("@/utils/permission");
var _auth = require("@/utils/auth");
var _validate = require("@/utils/validate");
var _MapContainer = _interopRequireDefault(require("@/components/MapContainer/MapContainer.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'Information',
  components: {
    MapContainer: _MapContainer.default
  },
  data: function data() {
    var _this2 = this;
    var checkPhone = function checkPhone(rule, value, callback) {
      if (value === '') {
        callback(new Error(_this2.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yis0')));
      } else {
        var regPone = null;
        var mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; // 最新16手机正则
        var tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; // 座机
        if (value.charAt(0) == 0) {
          // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
          regPone = tel;
        } else {
          regPone = mobile;
        }
        if (!regPone.test(value)) {
          return callback(new Error(_this2.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yk80')));
        }
        callback();
      }
    };
    var validateVal = function validateVal(rule, value, callback) {
      if (_this2.labelarr.length === 0) {
        callback(new Error(_this2.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yms0')));
      } else {
        callback();
      }
    };
    var validatePhone = function validatePhone(rule, value, callback) {
      if (!value) {
        return callback(new Error(_this2.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yoc0')));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error(_this2.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yqk0')));
      } else {
        callback();
      }
    };
    return {
      formConf: {
        fields: []
      },
      isCreate: 0,
      loginType: '1',
      headeNum: [{
        type: '1',
        name: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yrw0')
      }, {
        type: '2',
        name: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yto0')
      }, {
        type: '3',
        name: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yvk0')
      }],
      radio: '1',
      merData: {},
      // 默认数据
      submitLoading: false,
      // 提交loading
      editData: {},
      transferData: {},
      keyNum: 0,
      loading: false,
      merInfoForm: {
        avatar: '',
        backImage: '',
        streetBackImage: '',
        rectangleLogo: '',
        coverImage: '',
        intro: '',
        businessTime: '',
        keywords: '',
        alertStock: 1,
        addressDetail: '',
        serviceType: '',
        serviceLink: null,
        servicePhone: '',
        latitude: '',
        //纬度
        longitude: '',
        //经度
        isTakeTheir: false
      },
      rules: {
        // businessTime: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yx40'), trigger: 'blur'},
        // ],
        // intro: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yyk0'), trigger: 'blur'},
        // ],
        // avatar: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8zvw0'), trigger: 'change'},
        // ],
        // backImage: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc904g0'), trigger: 'change'},
        // ],
        // streetBackImage: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc906o0'), trigger: 'change'},
        // ],
        // coverImage: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90hk0'), trigger: 'change'},
        // ],
        // rectangleLogo: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90jo0'), trigger: 'change'},
        // ],
        // labelarr: [{required: true, validator: validateVal, trigger: 'blur'}],
        // alertStock: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90kw0'), trigger: 'blur'},
        // ],
        // serviceType: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90m40'), trigger: 'change'},
        // ],
        // serviceLink: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90o40'), trigger: 'blur'},
        // ],
        // servicePhone: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8yis0'), trigger: 'blur'},
        // ],
        // addressDetail: [
        //   {required: true, message: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90pg0'), trigger: 'blur'},
        // ],
      },
      keyUrl: '',
      labelarr: [],
      serviceList: [{
        value: 'H5',
        label: 'H5链接'
      }, {
        value: 'phone',
        label: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90rg0')
      }],
      settlementForm: {
        settlementType: 'bank'
      },
      formId: this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90sw0')
    };
  },
  watch: {
    'settlementForm.settlementType': {
      handler: function handler(val) {
        switch (val) {
          case 'bank':
            this.formId = '结算信息-银行卡';
            break;
          case 'wechat':
            this.formId = '结算信息-微信';
            break;
          default:
            this.formId = '结算信息-支付宝';
            break;
        }
        this.keyNum += 1;
      },
      immediate: false,
      deep: true
    }
  },
  created: function created() {
    if ((0, _permission.checkPermi)(['merchant:config:info'])) this.getConfigInfo();
    if ((0, _permission.checkPermi)(['merchant:settlement:info'])) this.SettlementInformation1();
    if ((0, _permission.checkPermi)(['merchant:base:info'])) this.getInfo();
  },
  mounted: function mounted() {
    window.addEventListener('message', function (event) {
      console.log(event.data);
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      var loc = event.data;
      if (loc && loc.module === 'locationPicker') {
        // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        window.parent.selectAdderss(loc);
      }
    }, false);
    window.selectAdderss = this.selectAdderss;
    this.keyUrl = "https://apis.map.qq.com/tools/locpicker?type=1&key=JAYBZ-KIVCL-N7XPI-MO6FF-E3P2E-VPF7L&referer=myapp";
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getLocation: function getLocation(e) {
      console.log(e);
      this.merInfoForm.latitude = e.latitude;
      this.merInfoForm.longitude = e.longitude;
      // this.merInfoForm.addressDetail = e.addressDetail
    },
    addressChange: function addressChange(e) {
      var _this3 = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this3.$refs.MapContainer.onSearch(e);
      }, 500);
    },
    getLabelarr: function getLabelarr(attr) {
      this.labelarr = attr;
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, val, maxSize) {
      var _this = this;
      this.$modalUpload(function (img) {
        switch (val) {
          case 'avatar':
            _this.merInfoForm.avatar = img[0].sattDir;
            break;
          case 'backImage':
            _this.merInfoForm.backImage = img[0].sattDir;
            break;
          case 'rectangleLogo':
            _this.merInfoForm.rectangleLogo = img[0].sattDir;
            break;
          case 'coverImage':
            _this.merInfoForm.coverImage = img[0].sattDir;
            break;
          default:
            _this.merInfoForm.streetBackImage = img[0].sattDir;
            break;
        }
      }, tit, 'content', '', maxSize);
    },
    // 选择经纬度
    selectAdderss: function selectAdderss(data) {
      this.merInfoForm.addressDetail = data.poiaddress;
      this.merInfoForm.latitude = data.latlng.lat;
      this.merInfoForm.longitude = data.latlng.lng;
    },
    changeSwitch: function changeSwitch() {
      var _this4 = this;
      var changeSwitch = this.merData.isSwitch ? this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8x3s0') : this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc8x6c0');
      this.$modalSure("".concat(changeSwitch, "\u8BE5\u5546\u6237\u5417")).then(function () {
        (0, _merchant.merchantSwitchApi)().then(function (res) {
          _this4.$modal.msgSuccess(_this4.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90xk0'));
        }).catch(function () {
          _this4.merData.isSwitch = !_this4.merData.isSwitch;
        });
      }).catch(function () {
        _this4.merData.isSwitch = !_this4.merData.isSwitch;
      });
    },
    handlerSubmit: (0, _validate.Debounce)(function (formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.merInfoForm.keywords = _this5.labelarr.join(',');
          (0, _merchant.merchantUpdateApi)(_this5.merInfoForm).then(function (res) {
            _this5.$message.success(_this5.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90zk0'));
            _this5.getConfigInfo();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }),
    transferhandlerSubmit: (0, _validate.Debounce)(function (formValue) {
      var _this6 = this;
      if ((0, _permission.checkPermi)(['merchant:settlement:info:edit'])) {
        (0, _merchant.merchantTransferEditApi)({
          id: this.formId,
          settlementType: this.settlementForm.settlementType,
          alipayCode: formValue.alipayCode,
          alipayQrcodeUrl: formValue.alipayQrcodeUrl,
          bankAddress: formValue.bankAddress,
          bankCard: formValue.bankCard,
          nameAddress: formValue.nameAddress,
          swiftCode: formValue.swiftCode,
          invoiceTitle: formValue.invoiceTitle,
          bankName: formValue.bankName,
          bankUserName: formValue.bankUserName,
          wechatCode: formValue.wechatCode,
          wechatQrcodeUrl: formValue.wechatQrcodeUrl,
          realName: formValue.realName
        }).then(function (res) {
          _this6.$message.success(_this6.$t('systemSetting.modifyStoreInfo.index.5u3dtmc90zk0'));
          _this6.getMerchantTransfer1();
        });
      } else {
        this.$message.warning(this.$t('systemSetting.modifyStoreInfo.index.5u3dtmc910o0'));
      }
    }),
    // 获取商户信息
    getInfo: function getInfo() {
      var _this7 = this;
      this.loading = true;
      (0, _merchant.getBaseInfoApi)().then(function (res) {
        _this7.merData = res;
        cosnole.log('aaaaaaaaaaaa');
        _this7.loading = false;
      }).catch(function () {
        _this7.loading = false;
      });
    },
    // 获取商户配置信息
    getConfigInfo: function getConfigInfo() {
      var _this8 = this;
      (0, _merchant.merchantConfigInfoApi)().then(function (res) {
        _this8.merInfoForm = res;
        var _this8$merInfoForm = _this8.merInfoForm,
          latitude = _this8$merInfoForm.latitude,
          longitude = _this8$merInfoForm.longitude;
        var coord = latitude ? "&coord=".concat(latitude, ",").concat(longitude) : '';
        _this8.keyUrl = "https://apis.map.qq.com/tools/locpicker?type=1&key=IQPBZ-P32KB-QGCU5-JIRB6-PXJGQ-JLFAG".concat(coord, "&referer=myapp");
        _this8.labelarr = res.keywords.split(',') || [];
      });
    },
    // 获取转账信息
    getMerchantTransfer1: function getMerchantTransfer1() {
      var _this9 = this;
      (0, _merchant.merchantTransferApi)().then(function (res) {
        _this9.transferData = res;
        // this.settlementForm = res;
        console.log(res, '啊啊啊啊啊啊');
      });
    },
    // 获取商户结算信息
    SettlementInformation1: function SettlementInformation1() {
      var _this10 = this;
      (0, _merchant.SettlementInformation)().then(function (res) {
        _this10.transferData = res;
        console.log(res, 11111);
        _this10.settlementForm = res || '';
        if (!_this10.settlementForm) {
          _this10.getMerchantTransfer1();
        }
        console.log(2222);
      });
    }
  }
};