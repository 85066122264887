"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = modalParserFrom;
/**
 * 弹窗样式的表单配置的提交
 * @param title 标题
 * @param formId 表单id
 * @param isCreate 是否是编辑
 * @param editData 详情数据
 * @param callback 回调函数
 * @param keyNum 重置表单key值
 * @returns {Promise<any>}
 */
function modalParserFrom(title, formName, isCreate, editData, callback, keyNum) {
  var _this = this;
  var h = this.$createElement;
  return new Promise(function (resolve, reject) {
    _this.$msgbox({
      title: title,
      customClass: 'upload-form',
      closeOnClickModal: false,
      showClose: true,
      message: h('div', {
        class: 'common-form-upload'
      }, [h('ZBParser', {
        props: {
          formName: formName,
          isCreate: isCreate,
          editData: editData,
          keyNum: keyNum
        },
        on: {
          submit: function submit(formValue) {
            callback(formValue);
          }
        }
      })]),
      showCancelButton: false,
      showConfirmButton: false
    }).then(function () {
      resolve();
    }).catch(function () {
      reject();
      // this.$message({
      //   type: 'info',
      //   message: '已取消'
      // })
    });
  });
}