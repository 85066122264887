"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var _product = require("@/api/product");
var _auth = require("@/utils/auth");
var _permission = require("@/utils/permission");
var _vuex = require("vuex");
var _storeEdit = _interopRequireDefault(require("./components/storeEdit"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// internationalization
// 权限判断函数
var objTitle = [(0, _utils.getLocaleI18n)('product.index.5u2p0efnlbw0'), (0, _utils.getLocaleI18n)('product.index.5u2p0efnlf00'), (0, _utils.getLocaleI18n)('product.index.5u2p0efnlis0'), (0, _utils.getLocaleI18n)('product.index.5u2p0efnlzk0'), (0, _utils.getLocaleI18n)('product.index.5u2p0efnm2g0'), (0, _utils.getLocaleI18n)('product.index.5u2p0efnm6g0'), (0, _utils.getLocaleI18n)('product.index.5u2p0efnm7w0')];
var _default = exports.default = {
  name: 'ProductList',
  directives: {
    // 计算是否滚动到最下面
    selectLoadMore: {
      bind: function bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        var SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          if (this.scrollHeight - this.scrollTop < this.clientHeight + 1) {
            binding.value();
          }
        });
      }
    }
  },
  components: {
    storeEdit: _storeEdit.default
  },
  data: function data() {
    return {
      drawer: false,
      direction: 'rtl',
      propsPlant: {
        children: 'childList',
        label: 'name',
        value: 'id',
        multiple: false,
        emitPath: false
      },
      propsMer: {
        children: 'childList',
        label: 'name',
        value: 'id',
        multiple: false,
        emitPath: false,
        checkStrictly: true
      },
      headeNum: [],
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        cateId: '',
        keywords: '',
        type: '1',
        categoryId: null
      },
      keywords: '',
      categoryList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      card_select_show: false,
      checkAll: false,
      checkedCities: ['ID', this.$t('product.index.5u2p0efnfxc0'), this.$t('product.index.5u2p0efng0s0'), this.$t('product.index.5u2p0efngqo0'), this.$t('product.index.5u2p0efnh5s0'), this.$t('product.index.5u2p0efni8c0'), this.$t('product.index.5u2p0efnm980'), this.$t('product.index.5u2p0efnjmg0')],
      columnData: ['ID', this.$t('product.index.5u2p0efnfxc0'), this.$t('product.index.5u2p0efng0s0'), this.$t('product.index.5u2p0efngqo0'), this.$t('product.index.5u2p0efnh5s0'), this.$t('product.index.5u2p0efni8c0'), this.$t('product.index.5u2p0efnm980'), this.$t('product.index.5u2p0efnjmg0')],
      isIndeterminate: true,
      productId: 0,
      stockEdit: false
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['adminProductClassify', 'merProductClassify', 'productBrand'])),
  activated: function activated() {
    this.goodHeade();
    this.getList();
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:product:tabs:headers'])) this.goodHeade();
    if ((0, _permission.checkPermi)(['merchant:product:page:list'])) this.getList();
    this.checkedCities = this.$cache.local.has('goods_stroge') ? this.$cache.local.getJSON('goods_stroge') : this.checkedCities;
    //this.$store.dispatch('product/getAdminProductClassify');
    this.$store.dispatch('product/getAdminProductClassify');
    if (!localStorage.getItem('merProductClassify')) this.$store.dispatch('product/getMerProductClassify');
    this.$store.dispatch('product/getMerProductBrand');
  },
  methods: {
    checkPermi: _permission.checkPermi,
    onEdit: function onEdit(id) {
      var _this = this;
      if (this.tableFrom.type === '1') {
        this.$modalSure(this.$t('product.index.5u2p0efnmbo0')).then(function () {
          (0, _product.offShellApi)(id).then(function () {
            _this.$router.push({
              path: "/product/list/creatProduct/".concat(id, "/2")
            });
          });
        });
      } else {
        this.$router.push({
          path: "/product/list/creatProduct/".concat(id, "/2")
        });
      }
    },
    subSuccess: function subSuccess() {
      this.handleCloseEdit();
    },
    handleEdit: function handleEdit(id, stockEdit) {
      this.productId = id;
      this.drawer = true;
      this.stockEdit = stockEdit;
    },
    handleCloseEdit: function handleCloseEdit() {
      this.drawer = false;
      this.seachList();
    },
    handleAudit: function handleAudit(id) {
      var _this2 = this;
      this.$modalSure(this.$t('product.index.5u2p0efnmi40')).then(function () {
        (0, _product.productAuditApi)(id).then(function (res) {
          _this2.goodHeade();
          _this2.getList();
        });
      });
    },
    handleRestore: function handleRestore(id) {
      var _this3 = this;
      this.$modalSure(this.$t('product.index.5u2p0efnkjs0')).then(function () {
        (0, _product.restoreApi)(id).then(function (res) {
          _this3.$message.success(_this3.$t('product.index.5u2p0efnmkk0'));
          _this3.goodHeade();
          _this3.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
      this.goodHeade();
    },
    // 导出
    exports: function exports() {
      (0, _product.productExcelApi)({
        cateId: this.tableFrom.cateId,
        keywords: this.tableFrom.keywords,
        type: this.tableFrom.type
      }).then(function (res) {
        window.location.href = res.fileName;
      });
    },
    // 获取商品表单头数量
    goodHeade: function goodHeade() {
      var _this4 = this;
      (0, _product.productHeadersApi)().then(function (res) {
        res.map(function (item) {
          item.name = objTitle[Number(item.type) - 1];
        });
        _this4.headeNum = res;
      }).catch(function (res) {
        _this4.$message.error(res.message);
      });
    },
    changeNodes: function changeNodes(data) {
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (!data[i].childList || data[i].childList.length < 1) {
            data[i].childList = undefined;
          } else {
            this.changeNodes(data[i].childList);
          }
        }
      }
      return data;
    },
    // 列表
    getList: function getList() {
      var _this5 = this;
      this.listLoading = true;
      this.tableFrom.keywords = encodeURIComponent(this.keywords);
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.list;
        _this5.tableData.total = res.total;
        _this5.listLoading = false;
      }).catch(function (res) {
        _this5.listLoading = false;
        _this5.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, type) {
      var _this6 = this;
      this.$modalSure(type == 5 ? "\u5220\u9664 id \u4E3A ".concat(id, " \u7684\u5546\u54C1") : "id\u4E3A".concat(id, "\u7684\u5546\u54C1\u52A0\u5165\u56DE\u6536\u7AD9\u5417")).then(function () {
        var deleteFlag = type == 5 ? 'delete' : 'recycle';
        (0, _product.productDeleteApi)({
          id: id,
          type: deleteFlag
        }).then(function () {
          _this6.$message.success(_this6.$t('product.index.5u2p0efnmnk0'));
          _this6.getList();
          _this6.goodHeade();
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this7 = this;
      row.isShow ? (0, _product.putOnShellApi)(row.id).then(function () {
        _this7.$message.success(_this7.$t('product.index.5u2p0efnmr40'));
        _this7.getList();
        _this7.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      }) : (0, _product.offShellApi)(row.id).then(function () {
        _this7.$message.success(_this7.$t('product.index.5u2p0efnmt00'));
        _this7.getList();
        _this7.goodHeade();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    },
    renderHeader: function renderHeader(h) {
      var _this8 = this;
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, [this.$t('product.index.5u2p0efnk2s0')]), h("i", {
        "class": "el-icon-setting",
        "on": {
          "click": function click() {
            return _this8.handleAddItem();
          }
        }
      })]);
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.$set(this, 'card_select_show', false);
      this.$modal.loading(this.$t('product.index.5u2p0efnmus0'));
      this.$cache.local.setJSON('goods_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    }
  }
};