"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.companyGetListApi = companyGetListApi;
exports.getLogisticsInfoApi = getLogisticsInfoApi;
exports.orderDeleteApi = orderDeleteApi;
exports.orderDetailApi = orderDetailApi;
exports.orderExcelApi = orderExcelApi;
exports.orderInvoiceListApi = orderInvoiceListApi;
exports.orderListApi = orderListApi;
exports.orderLogApi = orderLogApi;
exports.orderMarkApi = orderMarkApi;
exports.orderPrint = orderPrint;
exports.orderProDetailApi = orderProDetailApi;
exports.orderRefundApi = orderRefundApi;
exports.orderRefuseApi = orderRefuseApi;
exports.orderSendApi = orderSendApi;
exports.orderStatisticsApi = orderStatisticsApi;
exports.orderStatusNumApi = orderStatusNumApi;
exports.orderTimeApi = orderTimeApi;
exports.refundListApi = refundListApi;
exports.refundMarkApi = refundMarkApi;
exports.refundOrderDetailApi = refundOrderDetailApi;
exports.refundStatusNumApi = refundStatusNumApi;
exports.sheetInfoApi = sheetInfoApi;
exports.statisticsDataApi = statisticsDataApi;
exports.updatePriceApi = updatePriceApi;
exports.videoSendApi = videoSendApi;
exports.writeUpdateApi = writeUpdateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 订单 列表
 * @param prams
 */
function orderListApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/order/list',
    method: 'get',
    params: params
  });
}

/**
 * 订单 列表 获取各状态数量
 * @param params
 */
function orderStatusNumApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/order/status/num',
    method: 'get',
    params: params
  });
}

/**
 * 订单 删除
 * @param params
 */
function orderDeleteApi(orderNo) {
  return (0, _request.default)({
    url: "/admin/merchant/order/delete/".concat(orderNo),
    method: 'post'
  });
}

/**
 * 订单 记录
 * @param prams
 */
function orderLogApi(params) {
  return (0, _request.default)({
    url: '/admin/store/order/status/list',
    method: 'get',
    params: params
  });
}

/**
 * 订单 详情
 * @param prams
 */
function orderDetailApi(orderNo) {
  return (0, _request.default)({
    url: "/admin/merchant/order/info/".concat(orderNo),
    method: 'get'
  });
}

/**
 * 订单 备注
 * @param prams
 */
function orderMarkApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/order/mark',
    method: 'post',
    data: data
  });
}

/**
 * 订单 发货
 * @param prams
 */
function orderSendApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/order/send',
    method: 'post',
    data: data
  });
}

/**
 * 订单 拒绝退款
 * @param prams
 */
function orderRefuseApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/refund/order/refund/refuse',
    method: 'get',
    params: params
  });
}

/**
 * 订单 立即退款
 * @param prams
 */
function orderRefundApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/refund/order/refund',
    method: 'get',
    params: params
  });
}

/**
 * 订单 统计 头部数据
 */
function orderStatisticsApi() {
  return (0, _request.default)({
    url: "/admin/store/order/statistics",
    method: 'get'
  });
}

/**
 * 核销订单 月列表数据
 */
function statisticsDataApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/statisticsData",
    method: 'get',
    params: params
  });
}

/**
 * 一键改价
 */
function updatePriceApi(data) {
  return (0, _request.default)({
    url: "admin/store/order/update/price",
    method: 'post',
    data: data
  });
}

/**
 *订单统计详情
 */
function orderTimeApi(params) {
  return (0, _request.default)({
    url: "/admin/store/order/time",
    method: 'get',
    params: params
  });
}

/**
 *面单默认配置信息
 */
function sheetInfoApi() {
  return (0, _request.default)({
    url: "/admin/store/order/sheet/info",
    method: 'get'
  });
}

/**
 *面单默认配置信息
 */
function getLogisticsInfoApi(invoiceId) {
  return (0, _request.default)({
    url: "/admin/merchant/order/get/".concat(invoiceId, "/logistics/info"),
    method: 'get'
  });
}

/**
 *视频号物流公司
 */
function companyGetListApi() {
  return (0, _request.default)({
    url: "/admin/pay/component/delivery/company/get/list",
    method: 'get'
  });
}

/**
 *视频号物流公司
 */
function videoSendApi(data) {
  return (0, _request.default)({
    url: "/admin/store/order/video/send",
    method: 'post',
    data: data
  });
}

/**
 *打印小票
 */
function orderPrint(id) {
  return (0, _request.default)({
    url: "/admin/yly/print/".concat(id),
    method: 'get'
  });
}

/**
 *退款列表
 */
function refundListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/refund/order/list",
    method: 'get',
    params: params
  });
}

/**
 *退商户备注退款订单
 */
function refundMarkApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/refund/order/mark",
    method: 'post',
    data: data
  });
}

/**
 *获取退款订单各状态数量
 */
function refundStatusNumApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/refund/order/status/num",
    method: 'GET',
    params: params
  });
}

/**
 * 订单 核销订单
 * @param data
 */
function writeUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/order/verification",
    method: 'post',
    data: data
  });
}

/**
 * 订单细节详情列表（发货使用）
 * @param orderNo 订单号
 */
function orderProDetailApi(orderNo) {
  return (0, _request.default)({
    url: "/admin/merchant/order/".concat(orderNo, "/detail/list"),
    method: 'get'
  });
}

/**
 * 获取订单发货单列表
 * @param orderNo 订单号
 */
function orderInvoiceListApi(orderNo) {
  return (0, _request.default)({
    url: "/admin/merchant/order/".concat(orderNo, "/invoice/list"),
    method: 'get'
  });
}

/**
 * 平台端退款订单详情
 * @param refundOrderNo 订单号
 */
function refundOrderDetailApi(refundOrderNo) {
  return (0, _request.default)({
    url: "/admin/merchant/refund/order/detail/".concat(refundOrderNo),
    method: 'get'
  });
}

/**
 * 平台端退款订单详情
 * @param params 对象
 */
function orderExcelApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/export/order/excel",
    method: 'get',
    params: params
  });
}