"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Debounce = void 0;
exports.isArray = isArray;
exports.isExternal = isExternal;
exports.isString = isString;
exports.num = num;
exports.required = required;
exports.validAlphabets = validAlphabets;
exports.validEmail = validEmail;
exports.validLowerCase = validLowerCase;
exports.validURL = validURL;
exports.validUpperCase = validUpperCase;
exports.validUsername = validUsername;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Created by PanJiaChen on 16/11/18.
 */
var baseAttr = {
  min: '%s最小长度为:min',
  max: '%s最大长度为:max',
  length: '%s长度必须为:length',
  range: '%s长度为:range',
  pattern: '$s格式错误'
};

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
function validEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
var bindMessage = function bindMessage(fn, message) {
  fn.message = function (field) {
    return message.replace('%s', field || '');
  };
};
function required(message) {
  var opt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _objectSpread({
    required: true,
    message: message,
    type: 'string'
  }, opt);
}
bindMessage(required, '请输入%s');

/**
 * 正确的金额
 *
 * @param message
 * @returns {*}
 */
function num(message) {
  return attrs.pattern(/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message);
}
bindMessage(num, '%s格式不正确');
var attrs = Object.keys(baseAttr).reduce(function (attrs, key) {
  attrs[key] = function (attr) {
    var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var opt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var _attr = key === 'range' ? {
      min: attr[0],
      max: attr[1]
    } : _defineProperty({}, key, attr);
    return _objectSpread(_objectSpread({
      message: message.replace(":".concat(key), key === 'range' ? "".concat(attr[0], "-").concat(attr[1]) : attr),
      type: 'string'
    }, _attr), opt);
  };
  bindMessage(attrs[key], baseAttr[key]);
  return attrs;
}, {});
var _default = exports.default = attrs;
/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
var Debounce = exports.Debounce = function Debounce(fn, t) {
  var delay = t || 500;
  var timer;
  return function () {
    var _this = this;
    var args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      timer = null;
      fn.apply(_this, args);
    }, delay);
  };
};