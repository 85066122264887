"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderRefundStatusFilter = orderRefundStatusFilter;
exports.orderStatusFilter = orderStatusFilter;
exports.paidFilter = paidFilter;
exports.payTypeFilter = payTypeFilter;
exports.refundStatusFilter = refundStatusFilter;
//訂單過濾器

/**
 * @description 支付狀態
 */
function paidFilter(status) {
  var statusMap = {
    true: '已支付',
    false: '未支付'
  };
  return statusMap[status];
}

/**
 * @description 訂單狀態
 * 2,已收貨，待評價
 */
function orderStatusFilter(status) {
  var statusMap = {
    0: '待付款',
    1: '待发货',
    2: '部分发货',
    3: '待核销',
    4: '待收货',
    5: '已核销',
    6: '已完成',
    9: '已取消'
  };
  return statusMap[status];
}

/**
 * @description 訂單中的退款狀態
 *
 */
function orderRefundStatusFilter(status) {
  var statusMap = {
    0: '未退款',
    1: '申请退款中',
    2: '部分退款',
    3: '已退款'
  };
  return statusMap[status];
}

/**
 * @description 退款狀態
 * 2,已收貨，待評價
 */
function refundStatusFilter(status) {
  var statusMap = {
    0: '待审核',
    1: '拒绝退款',
    2: '退款中',
    3: '已退款'
  };
  return statusMap[status];
}

/**
 * @description 支付方式
 */
function payTypeFilter(status) {
  var statusMap = {
    weixin: '微信',
    alipay: '支付寶',
    cnbank: '中銀支付',
    yue: '余额'
  };
  return statusMap[status];
}