"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attrCreatApi = attrCreatApi;
exports.attrDeleteApi = attrDeleteApi;
exports.attrEditApi = attrEditApi;
exports.attrInfoApi = attrInfoApi;
exports.brandAllApi = brandAllApi;
exports.brandListApi = brandListApi;
exports.categoryApi = categoryApi;
exports.copyConfigApi = copyConfigApi;
exports.copyProductApi = copyProductApi;
exports.couponDeleteApi = couponDeleteApi;
exports.couponInfoApi = couponInfoApi;
exports.couponIssueStatusApi = couponIssueStatusApi;
exports.couponProductEditApi = couponProductEditApi;
exports.couponSaveApi = couponSaveApi;
exports.guaranteeAddApi = guaranteeAddApi;
exports.guaranteeDeleteApi = guaranteeDeleteApi;
exports.guaranteeListApi = guaranteeListApi;
exports.guaranteeUpdateApi = guaranteeUpdateApi;
exports.importProductApi = importProductApi;
exports.marketingListApi = marketingListApi;
exports.offShellApi = offShellApi;
exports.orderExcelApi = orderExcelApi;
exports.productActivityListApi = productActivityListApi;
exports.productAuditApi = productAuditApi;
exports.productCategoryAddApi = productCategoryAddApi;
exports.productCategoryDeleteApi = productCategoryDeleteApi;
exports.productCategoryListApi = productCategoryListApi;
exports.productCategoryShowApi = productCategoryShowApi;
exports.productCategoryUpdateApi = productCategoryUpdateApi;
exports.productCouponListApi = productCouponListApi;
exports.productCreateApi = productCreateApi;
exports.productDeleteApi = productDeleteApi;
exports.productDetailApi = productDetailApi;
exports.productExcelApi = productExcelApi;
exports.productExportApi = productExportApi;
exports.productFreeEditApi = productFreeEditApi;
exports.productGuaranteeApi = productGuaranteeApi;
exports.productHeadersApi = productHeadersApi;
exports.productLstApi = productLstApi;
exports.productStockAddApi = productStockAddApi;
exports.productUpdateApi = productUpdateApi;
exports.putOnShellApi = putOnShellApi;
exports.replyCommentApi = replyCommentApi;
exports.replyCreatApi = replyCreatApi;
exports.replyDeleteApi = replyDeleteApi;
exports.replyEditApi = replyEditApi;
exports.replyInfoApi = replyInfoApi;
exports.replyListApi = replyListApi;
exports.restoreApi = restoreApi;
exports.storeCategoryAllApi = storeCategoryAllApi;
exports.templateListApi = templateListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 新增商品
 * @param pram
 */
function productCreateApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/product/save',
    method: 'POST',
    data: data
  });
}

/**
 * 编辑商品
 * @param pram
 */
function productUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/product/update',
    method: 'POST',
    data: data
  });
}

/**
 * 商品详情
 * @param pram
 */
function productDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/product/info/".concat(id),
    method: 'GET'
  });
}

/**
 * 删除商品
 * @param pram
 */
function productDeleteApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/product/delete",
    method: 'post',
    data: data
  });
}

/**
 * 商品列表 表头数量
 */
function productHeadersApi() {
  return (0, _request.default)({
    url: '/admin/merchant/product/tabs/headers',
    method: 'GET'
  });
}

/**
 * 商品列表
 * @param pram
 */
function productLstApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/product/list',
    method: 'GET',
    params: params
  });
}
/**
 * 平台端商品分类缓存树
 * @param pram
 */
function categoryApi() {
  return (0, _request.default)({
    url: '/admin/merchant/plat/product/category/cache/tree',
    method: 'GET'
  });
}
/**
 * 商户端商品分类缓存树
 * @param pram
 */
function storeCategoryAllApi() {
  return (0, _request.default)({
    url: '/admin/merchant/store/product/category/cache/tree',
    method: 'GET'
  });
}
/**
 * 商品上架
 * @param pram
 */
function putOnShellApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/product/up/".concat(id),
    method: 'post'
  });
}
/**
 * 商品下架
 * @param pram
 */
function offShellApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/product/down/".concat(id),
    method: 'post'
  });
}
/**
 * 商品规格 列表
 * @param pram
 */
function templateListApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/product/rule/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品规格 删除
 * @param pram
 */
function attrDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/product/rule/delete/".concat(id),
    method: 'post'
  });
}
/**
 * 商品规格 新增
 * @param pram
 */
function attrCreatApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/product/rule/save',
    method: 'POST',
    data: data
  });
}
/**
 * 商品规格 编辑
 * @param pram
 */
function attrEditApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/product/rule/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品规格 详情
 * @param pram
 */
function attrInfoApi(id) {
  return (0, _request.default)({
    url: "admin/merchant/product/rule/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 列表
 * @param pram
 */
function replyListApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/product/reply/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品评论 新增
 * @param pram
 */
function replyCreatApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/product/reply/virtual',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 编辑
 * @param pram
 */
function replyEditApi(data) {
  return (0, _request.default)({
    url: '/admin/store/product/reply/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 详情
 * @param pram
 */
function replyInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/store/product/reply/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 删除
 * @param pram
 */
function replyDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/product/reply/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 商品评论 回复
 * @param pram
 */
function replyCommentApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/product/reply/comment",
    method: 'post',
    data: data
  });
}

/**
 * 商品评论 导出
 * @param pram
 */
function productExportApi(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/product",
    method: 'get',
    params: params
  });
}

/**
 * 商品复制 99Api
 * @param pram
 */
function importProductApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/product/importProduct",
    method: 'post',
    params: params
  });
}

/**
 * 商品复制 一号通
 * @param pram
 */
function copyProductApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/product/copy/product",
    method: 'post',
    data: data
  });
}

/**
 * 恢复
 * @param pram
 */
function restoreApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/product/restore/".concat(id),
    method: 'post'
  });
}

/**
 * 商品列表 导出
 * @param pram
 */
function productExcelApi(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/product",
    method: 'get',
    params: params
  });
}

/**
 * 商品列表 获取复制商品配置
 * @param pram
 */
function copyConfigApi() {
  return (0, _request.default)({
    url: "/admin/merchant/product/copy/config",
    method: 'post'
  });
}

/**
 * 订单数据 导出
 * @param pram
 */
function orderExcelApi(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/order",
    method: 'get',
    params: params
  });
}

/**
 * 商品分类 列表
 * @param pram
 */
function productCategoryListApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/store/product/category/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品分类 新增
 * @param pram
 */
function productCategoryAddApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/store/product/category/add',
    method: 'post',
    data: data
  });
}
/**
 * 商品分类 编辑
 * @param pram
 */
function productCategoryUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/merchant/store/product/category/update',
    method: 'post',
    data: data
  });
}
/**
 * 商品分类 删除
 * @param pram
 */
function productCategoryDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/store/product/category/delete/".concat(id),
    method: 'post'
  });
}
/**
 * 商品分类 修改分类显示状态
 * @param pram
 */
function productCategoryShowApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/store/product/category/update/show/".concat(id),
    method: 'post'
  });
}
/**
 * 保障服务列表
 * @param pram
 */
function productGuaranteeApi() {
  return (0, _request.default)({
    url: "/admin/merchant/plat/product/guarantee/list",
    method: 'get'
  });
}
/**
 * 品牌列表 全部
 * @param pram
 */
function brandAllApi() {
  return (0, _request.default)({
    url: "/admin/merchant/plat/product/brand/cache/list",
    method: 'get'
  });
}

/**
 * 品牌列表
 * @param pram
 */
function brandListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/plat/product/brand/list",
    method: 'get',
    params: params
  });
}

/**
 * 优惠券 列表
 * @param pram
 */
function marketingListApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/coupon/list',
    method: 'get',
    params: params
  });
}

/**
 * 商品可用优惠券列表
 * @param pram
 */
function productCouponListApi() {
  return (0, _request.default)({
    url: '/admin/merchant/coupon/product/usable/list',
    method: 'get'
  });
}

/**
 * 优惠券 详情
 * @param pram
 */
function couponInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/coupon/info/".concat(id),
    method: 'get'
  });
}

/**
 * 优惠券 修改状态
 * @param pram
 */
function couponIssueStatusApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/coupon/update/status/".concat(id),
    method: 'post'
  });
}

/**
 * 优惠券 删除
 * @param pram
 */
function couponDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/coupon/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 优惠券 新增
 * @param pram
 */
function couponSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/coupon/save",
    method: 'post',
    data: data
  });
}

/**
 * 优惠券 商品券关联商品编辑
 * @param pram
 */
function couponProductEditApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/coupon/product/join/edit",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务组合 列表
 * @param pram
 */
function guaranteeListApi(params) {
  return (0, _request.default)({
    url: "/admin/merchant/product/guarantee/group/list",
    method: 'get',
    params: params
  });
}

/**
 * 保障服务组合 新增
 * @param pram
 */
function guaranteeAddApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/product/guarantee/group/add",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务组合 编辑
 * @param pram
 */
function guaranteeUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/product/guarantee/group/edit",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务组合 删除
 * @param pram
 */
function guaranteeDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/product/guarantee/group/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 商品提审
 * @param pram
 */
function productAuditApi(id) {
  return (0, _request.default)({
    url: "/admin/merchant/product/submit/audit/".concat(id),
    method: 'post'
  });
}

/**
 * 快捷添加库存
 * @param pram
 */
function productStockAddApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/product/quick/stock/add",
    method: 'post',
    data: data
  });
}

/**
 * 商品免审编辑
 * @param pram
 */
function productFreeEditApi(data) {
  return (0, _request.default)({
    url: "/admin/merchant/product/review/free/edit",
    method: 'post',
    data: data
  });
}

/**
 * 商品搜索分页列表（活动）
 * @param pram
 */
function productActivityListApi(params) {
  return (0, _request.default)({
    url: '/admin/merchant/product/activity/search/page',
    method: 'get',
    params: params
  });
}