var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:order:page:list"],
              expression: "['platform:order:page:list']",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:export:order:excel"],
                      expression: "['platform:export:order:excel']",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.exports },
                },
                [_vm._v("导出\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": { fontWeight: "bold" },
                "row-key": function (row) {
                  return row.orderNo
                },
              },
            },
            [
              _vm.checkedCities.includes(_vm.$t("order.index.5tqsfbq9k380"))
                ? _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.index.5u3ctbxeyg80"),
                      "min-width": "220",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "font",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.type === 1,
                                          expression: "scope.row.type === 1",
                                        },
                                      ],
                                      attrs: { color: "#f6ae02" },
                                    },
                                    [_vm._v("[视频号]")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "font",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.type === 2,
                                          expression: "scope.row.type === 2",
                                        },
                                      ],
                                      attrs: { color: "#1890ff" },
                                    },
                                    [_vm._v("[秒杀]")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "ml5",
                                    class:
                                      parseInt(scope.row.refundStatus) > 0
                                        ? "red"
                                        : "",
                                    staticStyle: { display: "block" },
                                    domProps: {
                                      textContent: _vm._s(scope.row.orderNo),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        parseInt(scope.row.refundStatus) > 0,
                                      expression:
                                        "parseInt(scope.row.refundStatus) > 0",
                                    },
                                  ],
                                  staticStyle: {
                                    color: "#ed4014",
                                    display: "block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("orderRefundStatusFilter")(
                                        scope.row.refundStatus
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.isUserDel,
                                      expression: "scope.row.isUserDel",
                                    },
                                  ],
                                  staticStyle: {
                                    color: "#ed4014",
                                    display: "block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.index.5tqsfbq9k880"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.isMerchantDel,
                                      expression: "scope.row.isMerchantDel",
                                    },
                                  ],
                                  staticStyle: {
                                    color: "#ed4014",
                                    display: "block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.index.5tqsfbq9kdo0"))
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1571940445
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes(_vm.$t("order.index.5tqsfbq9kj40"))
                ? _c("el-table-column", {
                    attrs: {
                      prop: "merName",
                      label: "商户名称",
                      "min-width": "150",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "totalPrice", label: "订单总价" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  prop: "couponPrice",
                  label: "优惠券补贴金额",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "useractualpayment", label: "用户实付" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  prop: "realincome",
                  label: "商户真实收入",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120",
                  prop: "automaticAccountsplitting",
                  label: "自动分账收入",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  prop: "platformSubsidies",
                  label: "平台补贴",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  prop: "platCouponId",
                  label: "券id",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  prop: "couponName",
                  label: "券名称",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("order.index.5tqsfbq9loo0")) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("order.index.5tqsfbq9ltc0")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("details-from", {
        ref: "orderDetail",
        attrs: { orderNo: _vm.orderNo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }