var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c("div", { attrs: { id: "container" } }, [
      _c("div", { staticClass: "searchInput-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          attrs: { id: "searchInput", placeholder: "请输入关键字" },
          domProps: { value: _vm.input },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.input = $event.target.value
              },
              _vm.inputChane,
            ],
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-box" },
          _vm._l(_vm.serarchList, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                on: {
                  click: function ($event) {
                    return _vm.onSearch(item.name)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(item.name) + "\n          ")]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "my-panel" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }