"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activityStatusFilter = activityStatusFilter;
exports.articleTypeFilter = articleTypeFilter;
exports.bargainColorFilter = bargainColorFilter;
exports.bargainStatusFilter = bargainStatusFilter;
exports.couponCategoryFilter = couponCategoryFilter;
exports.couponTypeFilter = couponTypeFilter;
exports.couponUserTypeFilter = couponUserTypeFilter;
exports.editStatusFilter = editStatusFilter;
exports.extractStatusFilter = extractStatusFilter;
exports.extractTypeFilter = extractTypeFilter;
exports.filterCategroyType = filterCategroyType;
exports.filterConfigCategory = filterConfigCategory;
exports.filterEmpty = filterEmpty;
exports.filterShowOrHide = filterShowOrHide;
exports.filterShowOrHideForFormConfig = filterShowOrHideForFormConfig;
exports.filterYesOrNo = filterYesOrNo;
exports.filterYesOrNoIs = filterYesOrNoIs;
exports.formatDate = formatDate;
exports.groupColorFilter = groupColorFilter;
exports.groupStatusFilter = groupStatusFilter;
exports.keywordStatusFilter = keywordStatusFilter;
exports.onePassTypeFilter = onePassTypeFilter;
exports.payStatusFilter = payStatusFilter;
exports.platformStatusFilter = platformStatusFilter;
exports.rechargeTypeFilter = rechargeTypeFilter;
exports.roleTypeFilter = roleTypeFilter;
exports.videoStatusFilter = videoStatusFilter;
var constants = _interopRequireWildcard(require("@/utils/constants.js"));
var _index = require("@/utils/index");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// 公共過濾器
function filterEmpty(val) {
  var _result = '-';
  if (!val) {
    return _result;
  }
  _result = val;
  return _result;
}

// 時間過濾器
function formatDate(time) {
  if (time !== 0) {
    var date = new Date(time * 1000);
    return (0, _index.formatDates)(date, 'yyyy-MM-dd hh:mm');
  }
}
function filterYesOrNo(value) {
  return value ? '是' : '否';
}
function filterShowOrHide(value) {
  return value ? '顯示' : '不顯示';
}
function filterShowOrHideForFormConfig(value) {
  return value === '‘0’' ? '顯示' : '不顯示';
}
function filterYesOrNoIs(value) {
  return value ? '否' : '是';
}
function filterCategroyType(value) {
  return constants.categoryType.filter(function (item) {
    return value === item.value;
  })[0].name;
}
function filterConfigCategory(value) {
  return constants.configCategory.filter(function (item) {
    return value === item.value;
  })[0].label;
}

/**
 * @description 公眾號回覆類型
 */
function keywordStatusFilter(status) {
  var statusMap = {
    text: '文字消息',
    image: '圖片消息',
    news: '圖文消息',
    voice: '聲音消息'
  };
  return statusMap[status];
}

/**
 * @description 優惠券類別
 */
function couponCategoryFilter(status) {
  var statusMap = {
    1: '店鋪',
    2: '商品',
    3: '通用',
    4: '品類',
    5: '品牌',
    6: '跨店'
  };
  return statusMap[status];
}

/**
 * @description 優惠券類型
 */
function couponTypeFilter(status) {
  var statusMap = {
    1: '商家券',
    2: '商品券',
    3: '平台券'
  };
  return statusMap[status];
}

/**
 * @description 優惠券領取方式
 */
function couponUserTypeFilter(status) {
  var statusMap = {
    1: '手動領取',
    2: '贈送券'
  };
  return statusMap[status];
}

/**
 * @description 文章分類
 */
function articleTypeFilter(status) {
  if (!status) {
    return '';
  }
  var arrayList = JSON.parse(localStorage.getItem('articleClass'));
  if (arrayList.filter(function (item) {
    return Number(status) === Number(item.id);
  }).length < 1) {
    return '';
  }
  return arrayList.filter(function (item) {
    return Number(status) === Number(item.id);
  })[0].name;
}

/**
 * @description 支付狀態
 */
function payStatusFilter(status) {
  var statusMap = {
    false: '未支付',
    true: '已支付'
  };
  return statusMap[status];
}

/**
 * @description 提現方式
 */
function extractTypeFilter(status) {
  var statusMap = {
    bank: '銀行卡',
    alipay: '支付寶',
    weixin: '微信'
  };
  return statusMap[status];
}

/**
 * @description 充值類型
 */
function rechargeTypeFilter(status) {
  var statusMap = {
    public: '微信公眾號',
    weixinh5: '微信H5支付',
    routine: '小程序'
  };
  return statusMap[status];
}

/**
 * @description 財務審核狀態
 */
function extractStatusFilter(status) {
  var statusMap = {
    '-1': '已拒絕',
    0: '審核中',
    1: '已提現'
  };
  return statusMap[status];
}

/**
 * @description 砍價狀態
 */
function bargainStatusFilter(status) {
  var statusMap = {
    1: '進行中',
    2: '未完成',
    3: '已成功'
  };
  return statusMap[status];
}

/**
 * @description 砍價狀態
 */
function bargainColorFilter(status) {
  var statusMap = {
    1: '',
    2: 'danger',
    3: 'success'
  };
  return statusMap[status];
}

/**
 * @description 拼團狀態
 */
function groupStatusFilter(status) {
  var statusMap = {
    1: '進行中',
    2: '已成功',
    3: '未完成'
  };
  return statusMap[status];
}

/**
 * @description 拼團狀態
 */
function groupColorFilter(status) {
  var statusMap = {
    1: '',
    2: 'success',
    3: 'danger'
  };
  return statusMap[status];
}

/**
 * @description 一號通tab值
 */
function onePassTypeFilter(status) {
  var statusMap = {
    sms: '短信',
    copy: '商品採集',
    expr_query: '物流查詢',
    expr_dump: '電子面單打印'
  };
  return statusMap[status];
}

/**
 * @description 視頻號草稿商品微信審核狀態
 */
function editStatusFilter(status) {
  var statusMap = {
    '-1': '違規/風控系統下',
    '-2': '平台下架',
    '-3': '商家下架',
    1: '未審核',
    2: '審核中',
    3: '審核失敗',
    4: '審核成功'
  };
  return statusMap[status];
}

/**
 * @description 視頻號草稿商品平台審核狀態
 */
function platformStatusFilter(status) {
  var statusMap = {
    1: '未審核',
    2: '審核中',
    3: '審核失敗',
    4: '審核成功'
  };
  return statusMap[status];
}

/**
 * @description 視頻號正式商品狀態
 */
function videoStatusFilter(status) {
  var statusMap = {
    0: '初始值',
    5: '上架',
    11: '自主下架',
    13: '違規下架/風控系統下架'
  };
  return statusMap[status];
}

/**
 * @description 角色類型
 */
function roleTypeFilter(status) {
  var statusMap = {
    platform: '平台',
    merchant: '商户',
    system: '系統'
  };
  return statusMap[status];
}

/**
 * @description 秒殺商品活動狀態
 *
 */
function activityStatusFilter(status) {
  var statusMap = {
    0: '未開始',
    1: '進行中',
    2: '已結束'
  };
  return statusMap[status];
}