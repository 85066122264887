"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var financeRouter = {
  path: "/finance",
  name: 'finance',
  meta: {
    icon: '',
    title: '财务'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'statement',
    name: 'FinanceStatement',
    meta: {
      title: '账单管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/statement/index'));
      });
    }
  }, {
    path: 'incomeList',
    name: 'FinanceIncomeList',
    meta: {
      title: '收入明细',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/incomeList/index'));
      });
    }
  }, {
    path: 'capitalFlow',
    name: 'FinanceCapitalFlow',
    meta: {
      title: '资金流水',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/capitalFlow/index'));
      });
    }
  }, {
    path: 'closingRecord',
    name: 'FinanceClosingRecord',
    meta: {
      title: '结算记录 ',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/closingRecord/index'));
      });
    }
  }, {
    path: 'separate',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/finance/separate/index'));
      });
    },
    name: 'separate',
    meta: {
      title: '分账报表',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'detail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/finance/separate/detail/index'));
        });
      },
      name: 'Detail',
      meta: {
        title: '子账户明细记录',
        icon: ''
      }
    }, {
      path: 'accountInquiry',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/finance/separate/accountInquiry/index'));
        });
      },
      name: 'accountInquiry',
      meta: {
        title: '账户查询',
        icon: ''
      }
    }, {
      path: 'liquidateCheckAccounts',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/finance/separate/liquidateCheckAccounts'));
        });
      },
      name: 'liquidateCheckAccounts',
      meta: {
        title: '清算变动记录',
        icon: ''
      }
    }, {
      path: 'separateAccounts',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/finance/separate/separateAccounts'));
        });
      },
      name: 'separateAccounts',
      meta: {
        title: '订单分账记录',
        icon: ''
      }
    }]
  }]
};
var _default = exports.default = financeRouter;