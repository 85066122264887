//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { orderStatusNumApi, orderListApi, orderLogApi, orderMarkApi, orderDeleteApi, orderRefundApi, orderPrint, orderDetailApi, writeUpdateApi, orderExcelApi } from '@/api/order';
import detailsFrom from '@/components/orderDetail';
import orderSend from './orderSend';
import Cookies from 'js-cookie';
import { isWriteOff } from '@/utils';
import { checkPermi } from '@/utils/permission'; // 权限判断函数
export default {
  name: 'orderlistDetails',
  components: {
    detailsFrom: detailsFrom,
    orderSend: orderSend
  },
  data: function data() {
    return {
      RefuseVisible: false,
      RefuseData: {},
      orderNo: '',
      refundVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        orderNo: 0
      },
      LogLoading: false,
      isCreate: 1,
      editData: null,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        status: 'all',
        dateLimit: '',
        orderNo: '',
        page: 1,
        shippingTypeFilter: '',
        limit: 20,
        type: ''
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      fromType: [{
        value: '',
        text: this.$t('order.index.5u3ctbxexww0')
      }, {
        value: '0',
        text: this.$t('order.index.5u3ctbxez0w0')
      },
      // { value: '1', text: '视频号' },
      {
        value: '2',
        text: this.$t('order.index.5u3ctbxez280')
      }],
      selectionList: [],
      ids: '',
      orderids: '',
      cardLists: [],
      isWriteOff: isWriteOff(),
      proType: 0,
      active: false,
      card_select_show: false,
      checkAll: false,
      checkedCities: [this.$t('order.index.5u3ctbxeyg80'), this.$t('order.index.5u3ctbxez3k0'), this.$t('order.index.5u3ctbxeyk00'), this.$t('order.index.5u3ctbxeyls0'), this.$t('order.index.5u3ctbxeyn80'), this.$t('order.index.5u3ctbxeyp40'), this.$t('order.index.5u3ctbxeyss0')],
      columnData: [this.$t('order.index.5u3ctbxeyg80'), this.$t('order.index.5u3ctbxez3k0'), this.$t('order.index.5u3ctbxeyk00'), this.$t('order.index.5u3ctbxeyls0'), this.$t('order.index.5u3ctbxeyn80'), this.$t('order.index.5u3ctbxeyp40'), this.$t('order.index.5u3ctbxeyss0')],
      isIndeterminate: true,
      orderDatalist: null
    };
  },
  mounted: function mounted() {
    if (checkPermi(['merchant:order:page:list'])) this.getList();
    if (checkPermi(['merchant:order:status:num'])) this.getOrderStatusNum();
  },
  methods: {
    checkPermi: checkPermi,
    // 核销订单
    onWriteOff: function onWriteOff(row) {
      var _this = this;
      this.$modalPrompt('text', this.$t('order.index.5u3ctbxez500')).then(function (V) {
        writeUpdateApi({
          verifyCode: V
        }).then(function () {
          _this.$message.success(_this.$t('order.index.5u3ctbxez6c0'));
          _this.seachList();
        });
      });
    },
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 发送
    sendOrder: function sendOrder(row) {
      var _this2 = this;
      if (row.isLogoff) {
        this.$modalSure(this.$t('order.index.5u3ctbxez7k0')).then(function () {
          _this2.onSend(row);
        });
      } else {
        this.onSend(row);
      }
    },
    //发货操作
    onSend: function onSend(row) {
      this.orderNo = row.orderNo;
      this.$refs.send.modals = true;
      this.$refs.send.getList();
      this.$refs.send.orderProDetail(row.orderNo);
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this3 = this;
      if (row.isDel) {
        this.$modalSure().then(function () {
          orderDeleteApi({
            orderNo: row.orderNo
          }).then(function () {
            _this3.$message.success(_this3.$t('order.index.5u3ctbxez9k0'));
            _this3.tableData.data.splice(idx, 1);
          });
        });
      } else {
        this.$confirm(this.$t('order.index.5u3ctbxezas0'), this.$t('order.index.5u3ctbxezbw0'), {
          confirmButtonText: this.$t('order.index.5u3ctbxezd80'),
          type: 'error'
        });
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderNo = id;
      this.$refs.orderDetail.getDetail(id);
      this.$refs.orderDetail.getOrderInvoiceList(id);
      this.$refs.orderDetail.dialogVisible = true;
    },
    getDetail: function getDetail(id) {
      var _this4 = this;
      this.loading = true;
      orderDetailApi(id).then(function (res) {
        _this4.orderDatalist = res;
        _this4.loading = false;
      }).catch(function () {
        _this4.orderDatalist = null;
        _this4.loading = false;
      });
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this5 = this;
      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.orderNo = id;
      orderLogApi(this.tableFromLog).then(function (res) {
        _this5.tableDataLog.data = res.list;
        _this5.tableDataLog.total = res.total;
        _this5.LogLoading = false;
      }).catch(function () {
        _this5.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.onOrderLog();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.onOrderLog();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this6 = this;
      this.$modalPrompt('textarea', this.$t('order.index.5u3ctbxezec0'), row.merRemark).then(function (V) {
        orderMarkApi({
          remark: V,
          orderNo: row.orderNo
        }).then(function () {
          _this6.$message.success(_this6.$t('order.index.5u3ctbxezg00'));
          _this6.getList();
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.orderNo);
      });
      this.ids = data.join(',');
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 列表
    getList: function getList(num) {
      var _this7 = this;
      this.listLoading = true;
      if (num) {
        this.tableFrom.page = num;
      }
      orderListApi(this.tableFrom).then(function (res) {
        _this7.tableData.data = res.list || [];
        _this7.tableData.total = res.total;
        _this7.listLoading = false;
        _this7.checkedCities = _this7.$cache.local.has('order_stroge') ? _this7.$cache.local.getJSON('order_stroge') : _this7.checkedCities;
      }).catch(function () {
        _this7.listLoading = false;
      });
    },
    // 获取各状态数量
    getOrderStatusNum: function getOrderStatusNum() {
      var _this8 = this;
      orderStatusNumApi({
        dateLimit: this.tableFrom.dateLimit,
        type: this.tableFrom.type
      }).then(function (res) {
        _this8.orderChartType = res;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    exports: function exports() {
      var data = {
        dateLimit: this.tableFrom.dateLimit,
        orderNo: this.tableFrom.orderNo,
        status: this.tableFrom.status,
        type: this.tableFrom.type,
        shippingTypeFilter: this.tableFrom.shippingTypeFilter
      };
      orderExcelApi(data).then(function (res) {
        window.open(res.fileName);
      });
    },
    renderHeader: function renderHeader(h) {
      var _this9 = this;
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, [this.$t('order.index.5u3ctbxeytw0')]), h("i", {
        "class": "el-icon-setting",
        "on": {
          "click": function click() {
            return _this9.handleAddItem();
          }
        }
      })]);
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.$set(this, 'card_select_show', false);
      this.$modal.loading(this.$t('order.index.5u3ctbxezh40'));
      this.$cache.local.setJSON('order_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    },
    //打印小票
    onOrderPrint: function onOrderPrint(data) {
      var _this10 = this;
      orderPrint(data.orderNo).then(function (res) {
        _this10.$modal.msgSuccess(_this10.$t('order.index.5u3ctbxezj00'));
      }).catch(function (error) {
        _this10.$modal.msgError(error.message);
      });
    }
  }
};